<template>
  <div class="step-one">
    <AssociationScroll :showTabs="false">
      <AssociationScrollView title="项目信息" name="0">
        <el-row :gutter="10">
          <el-col
            :xs="24"
            :sm="12"
            :md="12"
            :lg="8"
            :xl="6"
            v-for="(item, index) in showContentData.basicsData"
            :key="index + 999"
          >
            <div class="detailData-text">
              {{ item.label }}
              <span v-if="Array.isArray(item.key)">
                <span v-for="(value, index) in item.key" :key="value"
                  >{{ detailData[value]
                  }}{{ index >= item.key.length - 1 ? "" : " ~ " }}</span
                >
              </span>
              <span v-else-if="item.key == 'enable'">{{
                detailData[item.key] == 1 ? "启用" : "禁用"
              }}</span>
              <span v-else>{{ detailData[item.key] || detailData[item.key] === 0 || detailData[item.key]==='0' ? detailData[item.key] : '' || "--" }}</span>
            </div>
          </el-col>
        </el-row>
      </AssociationScrollView>
      <AssociationScrollView title="项目概览" name="5">
        <overview :detailsData="detailData" />
      </AssociationScrollView>
      <AssociationScrollView title="销售指标" name="1">
        <indicators :detailData="detailData" />
      </AssociationScrollView>
      <div class="project-details-tabs">
        <el-tabs
          type="border-card"
          v-model="tabsIndex"
          @tab-click="handleTabClick"
        >
          <el-tab-pane
            :label="tabItem.label"
            :name="index + ''"
            v-for="(tabItem, index) in showContentData.statisticsData"
            :key="index"
          >
            <component
              :ref="'tabsModule' + index"
              :is="tabItem.components"
              title=""
              :isEdit="false" v-loading="fileloading"
              :label="tabItem.label"
              :option="tabItem.option"
              v-model="detailData[tabItem.key]"
              @changeDetails="handleChangeDetails"
            >
              <template slot="policyNo" slot-scope="{ item }">
                <div
                  class="el-button polecy-button el-button--text el-button--small"
                  style="text-decoration: underline; padding: 0"
                  @click="handleGoDetails('保单数据', item)"
                >
                  {{ item.policyNo }}
                </div>
              </template>
              <template slot="realCaseNo" slot-scope="{ item }">
                <div
                  class="el-button polecy-button el-button--text el-button--small"
                  style="text-decoration: underline; padding: 0"
                  @click="handleGoDetails('案件数据', item)"
                >
                  {{ item.realCaseNo }}
                </div>
              </template>
              <template slot="state" slot-scope="{ item }">
                <el-tag v-if="item.state == 2">保障中</el-tag>
                <el-tag type="warning" v-else-if="item.state == 1"
                  >未生效</el-tag
                >
                <el-tag type="info" v-else>已过期</el-tag>
              </template>
              <template slot="authState" slot-scope="{ item }">
                  <el-tag
                    type="success"
                    v-if="item.authState != '未认证'"
                    >{{ item.authState }}</el-tag
                  >
                  <el-tag type="danger" v-else>{{
                    item.authState
                  }}</el-tag>
              </template>
              <template slot="isDisplay" slot-scope="{ item }">
                <textSwitch v-model="item.isDisplay" active-text="否" ::disabled="!hasPerms('project-edit')" inactive-text="是" @change="handleChangeDispaly($event, index, item)">
                </textSwitch>
              </template>
              
              <template slot="shortStatus" slot-scope="{ item }">
                <el-tag
                  type="success"
                  v-if="statusClass[item.shortStatusDesc] == '1'"
                  >{{ item.shortStatusDesc }}</el-tag
                >
                <el-tag
                  type="warning"
                  v-if="statusClass[item.shortStatusDesc] == '3'"
                  >{{ item.shortStatusDesc }}</el-tag
                >
                <el-tag v-if="statusClass[item.shortStatusDesc] == '2'">{{
                  item.shortStatusDesc
                }}</el-tag>
                <el-tag
                  type="info"
                  v-if="statusClass[item.shortStatusDesc] == '4'"
                  >{{ item.shortStatusDesc }}</el-tag
                >
                <el-tag
                  type="danger"
                  v-if="statusClass[item.shortStatusDesc] == '5'"
                  >{{ item.shortStatusDesc }}</el-tag
                >
              </template>
              <template slot="playersType" slot-scope="{ item }">
                {{ item.playersType == 1 ? "团体" : "个人" }}
              </template>
              <template slot="origin" slot-scope="{ item }">
                {{ item.origin == 1 ? "国内" : "国外" }}
              </template>
              <template slot="avatar" slot-scope="{ item }">
                <img
                  :src="item.avatar"
                  style="width: 40px; height: 40px"
                  alt=""
                  srcset=""
                />
              </template>
              <template slot="isJobAuth" slot-scope="{ item }">
                <el-tag type="success" v-if="item.isJobAuth">有效</el-tag>
                <el-tag type="info" v-else>已过期</el-tag>
              </template>
              <template slot="terminalStatus" slot-scope="{ item }">
                <el-tag type="success" v-if="item.terminalStatus">已安装</el-tag>
                <el-tag type="info" v-else>未安装</el-tag>
              </template>
              
              <template slot="trainingSatisfaction" slot-scope="{ item }">
                <el-rate
                  :max="5"
                  disabled
                  v-model="item.trainingSatisfaction"
                ></el-rate>
              </template>
              <!-- 操作台 -->
              <template slot="operate" slot-scope="{ item }">
                <el-button
                  type="text" v-if="tabItem.label != '培训记录'"
                  @click="handleGoDetails(tabItem.label, item)"
                  >查看详情</el-button
                >
                <!-- <el-button
                  type="text"
                  v-if="tabItem.label == '风控数据'"
                  @click="handleReview(item)"
                  >培训回顾</el-button
                > -->
                <el-button
                  type="text"
                  v-if="tabItem.label == '培训记录'"
                  @click="handleEdit(item)"
                  >编辑</el-button
                >
                <el-button
                  type="text" style="color:rgba(246, 74, 45, 1)"
                  v-if="tabItem.label == '培训记录'"
                  @click="handleEdit(item, 'del')"
                  >删除</el-button
                >
              </template>
            </component>
          </el-tab-pane>
        </el-tabs>
      </div>
    </AssociationScroll>
    <RiskDetailsDialog v-model="isRiskDialog" :currentRow="currentRow" />
    <TrainingReviewDialog
      :show.sync="trainingReviewVisible"
      :id="trid"
    ></TrainingReviewDialog>
  </div>
</template>

<script>
import AssociationScroll from "@/components/AssociationScroll/AssociationScroll.vue";
import AssociationScrollView from "@/components/AssociationScroll/AssociationScrollView.vue";
import textSwitch from "@/components/textSwitch/index.vue";
import {
  getProjectDetail,
  riskDataDownload,
  projectDeviceDownload,
  jsDataDownload,
  listApplyDownload,
  projectDetailExport,
} from "@/api/project";
import { newRiskProjectDetail, projectCustomerPage, exportCustomer, projectFilePage, projectPolicyListPage, exportProjectPolicy, exportProjectCase, projectCasePage, projectDevicePage, exportProjectDevice, projectJsInfoPage, exportProjectJsInfo, projectTrainingRecordPage, exportTrainingRecord, swProjectDisplay, delTrainingRecordPage, exportSubjectProject, subjectProject, exportPolicySubjectPage, policySubjectPage, policyCustomerPage, exportPolicyCustomer } from "@/api/basicData";
import TargetTabs from "@/views/routerProjectManagement/components/ProjectList/components/TargetTabs.vue";
import vacancy from "@/views/routerProjectManagement/components/ProjectList/components/vacancy.vue";
import statisticsTable from "@/views/routerProjectManagement/components/ProjectList/components/statisticsTable.vue";
import RiskDetailsDialog from "@/views/routerProjectManagement/components/ProjectList/components/RiskDetailsDialog.vue";
import overview from "@/views/routerProjectManagement/components/ProjectList/components/overview.vue";
import indicators from "@/views/routerProjectManagement/components/ProjectList/components/indicators.vue";
import FormListUpload from "@/components/FormListUpload/index.vue";
import TrainingReviewDialog from "@/views/RiskDecrement/riskHomeManagement/components/TrainingReviewDialog.vue";
import { hasPerms } from "@/utils/auth";
export default {
  components: {
    AssociationScroll,
    AssociationScrollView,
    textSwitch,
    FormListUpload,
    TargetTabs,
    statisticsTable,
    RiskDetailsDialog,
    TrainingReviewDialog,
    vacancy,
    overview,
    indicators,
  },
  props: {
    // detailData: {
    //   type: Object,
    //   default: () => { }
    // }
  },
  computed: {
  },
  created() {
    this.getDetail();
  },
  activated() {
    this.getDetail();
  },
  data() {
    return {
      trainingReviewVisible: false,
      trid: "",
      detailData: {
        fileList: [],
      },
      fileloading: false,
      statusClass: {
        已接案: "1",
        定损中: "2",
        核算中: "2",
        验标中: "2",
        核赔中: "2",
        理算中: "2",
        支付中: "2",
        已中止: "3",
        已结案: "3",
        重开结案: "4",
        案件重开: "5",
      },
      showContentData: {
        basicsData: [
          {
            label: "项目编号：",
            key: "projectSequence",
          },
          {
            label: "项目名称：",
            key: "projectName",
          },
          {
            label: "主项目：",
            key: "parentProjectName",
          },
          {
            label: "所属客户：",
            key: "customer",
          },
          {
            label: "项目负责人：",
            key: "projectLeader",
          },
          {
            label: "启用状态：",
            key: "enable",
          },
          {
            label: "排序：",
            key: "order",
          },
          {
            label: "创建时间：",
            key: "createTime",
          },
          {
            label: "创建人：",
            key: "createUserName",
          },
        ],
        indicators: [
          {
            label: "签约保费：",
            key: "signPremiums",
            units: '元'
          },
          {
            label: "签约赔付率：",
            key: "signCompensateRate",
            units: '%'
          },
          {
            label: "目标赔付率：",
            key: "targetCompensate",
            units: '%'
          },
          {
            label: "签约管控率：",
            key: "signCtrlRate",
            units: '%'
          },
          {
            label: "目标管控率：",
            key: "targetCtrlRate",
            units: '%'
          },
        ],
        statisticsData: [
          {
            label: "客户数据",
            key: "customerInfoList",
            components: "statisticsTable",
            option: {
              api: policyCustomerPage,
              apiData: {
              },
              exportApi: exportPolicyCustomer,
              isExport: true,
              rowPlaceholder: "--",
              tableList: [
                {
                  prop: "playersName",
                  label: "客户名称",
                },
                {
                  prop: "contactPhone",
                  label: "联系电话",
                },
                {
                  prop: "cardNo",
                  label: "证件号码",
                  width: "180",
                },
                {
                  prop: "subjectCount",
                  label: "标的总数",
                },
                {
                  prop: "caseCount",
                  label: "案件总数",
                },
                {
                  prop: "claimsMoney",
                  label: "赔付总金额",
                },
                {
                  prop: "policyCount",
                  label: "保单数量",
                },
                {
                  prop: "projectMoney",
                  label: "项目总保费",
                },
                {
                  prop: "projectCompensate",
                  label: "项目赔付率",
                },
              ],
            },
          },
          {
            label: "保单数据",
            key: "4",
            components: "statisticsTable",
            option: {
              api: projectPolicyListPage,
              apiData: {
                isTop: true,
              },
              exportApi: exportProjectPolicy,
              isExport: true,
              rowPlaceholder: "--",
              isKeyWord: true,
              showSelect: true,
              selectList: [
                {
                  label: '全部',
                  value: '',
                },
                {
                  label: '未起保',
                  value: '1',
                },
                {
                  label: '保障中',
                  value: '2',
                },
                {
                  label: '已过期',
                  value: '3',
                },
                {
                  label: '待续保',
                  value: '4',
                },
              ],
              tableList: [
                {
                  prop: "projectName",
                  label: "项目名称",
                },
                {
                  prop: "policyNo",
                  label: "保单号",
                  isSlot: true,
                  fixed: true,
                  width: "192",
                },
                {
                  prop: "insuranceOfDate",
                  label: "投保日期",
                },
                {
                  prop: "term",
                  label: "保障期限",
                },
                {
                  prop: "state",
                  label: "保单状态",
                  isSlot: true,
                  width: "92",
                },
                {
                  prop: "totalPremium",
                  label: "总保费(元)",
                },
                {
                  prop: "policyHolderName",
                  label: "投保人",
                },
                {
                  prop: "insuredName",
                  label: "被保险人",
                },
                {
                  prop: "insuredCategoryName",
                  label: "险种类型",
                },
                {
                  prop: "primaryInsuranceCompanyName",
                  label: "保险公司",
                },
                {
                  prop: "primaryInsuranceBranchName",
                  label: "分支机构",
                },
                {
                  prop: "deviceTypeName",
                  label: "设备类型",
                },
                {
                  prop: "plate",
                  label: "车牌号",
                },
                {
                  prop: "frameNo",
                  label: "车架号",
                },
                {
                  prop: "salesmanName",
                  label: "业务员",
                },
                {
                  prop: "salesmanDeptName",
                  label: "所属部门",
                },
                {
                  prop: "customerName",
                  label: "所属客户",
                },
                {
                  prop: "createTime",
                  label: "录单时间",
                },
                {
                  prop: "isDisplay",
                  isSlot: true,
                  label: "是否显示",
                },
                {
                  prop: "operate",
                  label: "操作",
                  isSlot: true,
                },
              ],
            },
          },
          {
            label: "保单标的",
            key: "41",
            components: "statisticsTable",
            option: {
              api: policySubjectPage,
              apiData: {
              },
              exportApi: exportPolicySubjectPage,
              isExport: true,
              rowPlaceholder: "--",
              isKeyWord: true,
              showSelect: true,
              selectList: [
                {
                  label: '全部',
                  value: '',
                },
                {
                  label: '已安装',
                  value: true,
                },
                {
                  label: '未安装',
                  value: false,
                },
              ],
              tableList: [
                {
                  prop: "brandName",
                  label: "设备品牌",
                },
                {
                  prop: "deviceTypeName",
                  label: "设备类型",
                  // isSlot: true,
                  // fixed: true,
                  width: "192",
                },
                {
                  prop: "equipmentType",
                  label: "设备型号",
                },
                {
                  prop: "frameNo",
                  label: "车架号",
                },
                {
                  prop: "plate",
                  label: "车牌号",
                  // width: "92",
                },
                {
                  prop: "productionDate",
                  label: "出厂日期",
                },
                {
                  prop: "purchasePrice",
                  label: "新设备购置价",
                },
                {
                  prop: "curbWeight",
                  label: "整备质量",
                },
                {
                  prop: "yearlyDepreciation",
                  label: "年折旧率",
                },
                {
                  prop: "origin",
                  label: "产地",
                  isSlot: true,
                },
                {
                  prop: "terminalStatus",
                  label: "终端状态",
                  isSlot: true,
                },
              ],
            },
          },
          {
            label: "案件数据",
            key: "5",
            components: "statisticsTable",
            option: {
              api: projectCasePage,
              apiData: {
              },
              exportApi: exportProjectCase,
              isExport: true,
              isKeyWord: true,
              showSelect: true,
              selectList: [
                {
                  label: '全部',
                  value: '',
                },
                {
                  label: '已中止',
                  value: '1',
                },
                {
                  label: '已结案',
                  value: '2',
                },
                {
                  label: '案件重开',
                  value: '3',
                },
                {
                  label: '重开结案',
                  value: '4',
                },
                {
                  label: '已接案',
                  value: '5',
                },
                {
                  label: '验标中',
                  value: '6',
                },
                {
                  label: '定损中',
                  value: '7',
                },
                {
                  label: '核赔中',
                  value: '8',
                },
                {
                  label: '理算中',
                  value: '9',
                },
                {
                  label: '支付中',
                  value: '0',
                },
              ],
              rowPlaceholder: "--",
              tableList: [
                {
                  prop: "realCaseNo",
                  label: "报案号",
                  isSlot: true,
                },
                {
                  prop: "policyNo",
                  label: "保单号",
                  isSlot: true,
                },
                {
                  prop: "policyCompany",
                  label: "保险公司",
                },
                {
                  prop: "dangerType",
                  label: "出险类型",
                },
                {
                  prop: "dangerDate",
                  label: "出险时间",
                },
                {
                  prop: "dangerDetailAddress",
                  label: "出险地点",
                  width: "192",
                },
                {
                  prop: "reportCaseTime",
                  label: "报案时间",
                  width: "170",
                },
                {
                  prop: "shortStatus",
                  label: "案件状态",
                  isSlot: true,
                  width: "92",
                },
                {
                  prop: "realCompensationAmount",
                  label: "赔付金额",
                },
                {
                  prop: "jiShouName",
                  label: "涉案机手",
                },
                {
                  prop: "caseFollower",
                  label: "案件跟进人",
                },
                {
                  prop: "createTime",
                  label: "创建时间",
                },
                {
                  prop: "isDisplay",
                  isSlot: true,
                  label: "是否显示",
                },
                {
                  prop: "operate",
                  label: "操作",
                  isSlot: true,
                },
              ],
            },
          },
          {
            label: "机械设备",
            key: "6",
            components: "statisticsTable",
            option: {
              api: projectDevicePage,
              apiData: {
                isTop: true,
              },
              exportApi: exportProjectDevice,
              isExport: true,
              isKeyWord: true,
              rowPlaceholder: "--",
              tableList: [
                {
                  prop: "deviceName",
                  label: "设备名称",
                },
                {
                  prop: "groupName",
                  label: "所属车组",
                },
                {
                  prop: "frameNo",
                  label: "车架号",
                },
                {
                  prop: "categoryName",
                  label: "机械种类",
                },
                {
                  prop: "typeName",
                  label: "设备类型",
                },
                {
                  prop: "brandName",
                  label: "设备品牌",
                },
                {
                  prop: "terminalNo",
                  label: "终端号",
                },
                {
                  prop: "jsName",
                  label: "绑定机手",
                },
                {
                  prop: "createTime",
                  label: "创建时间",
                },
                {
                  prop: "isDisplay",
                  isSlot: true,
                  label: "是否显示",
                },
                {
                  prop: "operate",
                  label: "操作",
                  isSlot: true,
                },
              ],
            },
          },
          {
            label: "机手数据",
            key: "7",
            components: "statisticsTable",
            option: {
              api: projectJsInfoPage,
              apiData: {
              },
              exportApi: exportProjectJsInfo,
              isExport: true,
              rowPlaceholder: "--",
              tableList: [
                {
                  prop: "jiShouName",
                  label: "机手姓名",
                },
                {
                  prop: "jiShouIdNumber",
                  label: "机手证件",
                },
                {
                  prop: "jiShouAge",
                  label: "机手年龄",
                  width: "102",
                },
                {
                  prop: "jiShouExperience",
                  label: "机手工龄",
                  width: "102",
                },
                {
                  prop: "inProjectDateTime",
                  label: "进入项目日期",
                },
                {
                  prop: "inProjectDay",
                  label: "项目服务天数",
                },
                {
                  prop: "isJobAuth",
                  label: "证书信息",
                  isSlot: true,
                },
                {
                  prop: "inProjectClaimsCount",
                  label: "项目内理赔案件",
                },
                {
                  prop: "inProjectClaimsAmount",
                  label: "理赔案件赔付金额(元)",
                },
              ],
            },
          },
          {
            label: "风控数据",
            key: "9",
            components: "vacancy",
            option: {
              api: projectTrainingRecordPage,
              apiData: {
              },
              exportApi: exportTrainingRecord,
              isExport: true,
              rowPlaceholder: "--",
              tableList: [
                {
                  prop: "recordNo",
                  label: "培训编号",
                },
                {
                  prop: "customerName",
                  label: "客户名称",
                },
                {
                  prop: "trainingType",
                  label: "培训类型",
                },
                {
                  prop: "trainingDate",
                  label: "培训日期",
                },
                {
                  prop: "fullAddress",
                  label: "培训地点",
                },
                {
                  prop: "participationNumber",
                  label: "参与人数",
                },
                {
                  prop: "trainingSatisfaction",
                  label: "客户满意度",
                  width: "150",
                  isSlot: true,
                },
                {
                  prop: "expenditure",
                  label: "费用花销(元)",
                  width: "112",
                },
                {
                  prop: "remark",
                  label: "备注",
                },
                {
                  prop: "teacherName",
                  label: "培训人员",
                },
                {
                  prop: "createUserName",
                  label: "创建人",
                },
                {
                  prop: "createTime",
                  label: "创建时间",
                },
                {
                  prop: "operate",
                  label: "操作",
                  isSlot: true,
                  width: "150",
                },
              ],
            },
          },
          {
            label: "培训记录",
            key: "10",
            components: "statisticsTable",
            option: {
              api: projectTrainingRecordPage,
              apiData: {
              },
              exportApi: exportTrainingRecord,
              isExport: true,
              isKeyWord: true,
              isAdd: true,
              rowPlaceholder: "--",
              tableList: [
                {
                  prop: "recordNo",
                  label: "培训编号",
                },
                {
                  prop: "customerName",
                  label: "客户名称",
                },
                {
                  prop: "trainingType",
                  label: "培训类型",
                },
                {
                  prop: "trainingDate",
                  label: "培训日期",
                },
                {
                  prop: "fullAddress",
                  label: "培训地点",
                },
                {
                  prop: "participationNumber",
                  label: "参与人数",
                },
                {
                  prop: "trainingSatisfaction",
                  label: "客户满意度",
                  width: "150",
                  isSlot: true,
                },
                {
                  prop: "expenditure",
                  label: "费用花销(元)",
                  width: "112",
                },
                {
                  prop: "remark",
                  label: "备注",
                },
                {
                  prop: "teacherName",
                  label: "培训人员",
                },
                {
                  prop: "createUserName",
                  label: "创建人",
                },
                {
                  prop: "createTime",
                  label: "创建时间",
                },
                {
                  prop: "operate",
                  label: "操作",
                  isSlot: true,
                  width: "150",
                },
              ],
            },
          },
        ],
      },
      isRiskDialog: false,
      currentRow: {},
      tabsIndex: "0",
      targetInfo: [],
      hasPerms,
    };
  },
  watch: {
    detailData: {
      handler(v) {},
      deep: true,
      immediate: true,
    },
  },
  methods: {
    handleGoPath(path) {
      this.$router.push({ path });
    },
    handleGoDetails(type, item) {
      switch (type) {
        case "保单数据":
          this.handleGoPath(
            `/routerPolicyManagement/${ item.category == 1 ? 'ConstructionMachinery' : item.category == 2 ? 'CarInsurance' :  'Insurance'}/policyDetails/` +
              (item.policyId || item.id)
          );
          break;
        case "案件数据":
          this.handleGoPath("/routerClaimManagement/claimDetails/" + item.id);
          break;
        case "机械设备":
          sessionStorage.setItem(
            "vehicleIdList",
            JSON.stringify(this.$refs["tabsModule" + this.tabsIndex][0].tableData.map((val) => val.id))
          );
          this.$nextTick(() => {
            this.$router.push({
              path: '/SCDetails',
              query: {
                vehicleIndex: this.$refs["tabsModule" + this.tabsIndex][0].tableData.findIndex(
                  (val) => val.id == item.id
                ),
              },
            });
          });
          break;

        case "风控数据":
          this.isRiskDialog = true;
          this.currentRow = item;
          break;
      }
    },
    async handleEdit(e, type) {
      if (type == 'del') {
       this.$confirm('确认删除该数据？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(async() => {
          let data = await delTrainingRecordPage([e.id]);
          if (data.code === 200) {
            this.$message({
              type: 'success',
              message: '删除成功!'
            });
            this.$nextTick(() => {
                this.$refs["tabsModule" + this.tabsIndex][0].handleGetList();
            });
          }else {
            this.$message({
              type: 'error',
              message: '删除失败'
            });   
          }
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          });          
        });
        return false;
      };
      this.$refs["tabsModule" + this.tabsIndex][0].handleEditData(e);
      // this.$router.push({
      //   path: `/routerProjectManagement/addSubProject${
      //     e.id ? "?id=" + e.id : ""
      //   }`,
      // });
    },
    async getDetail() {
      try{
        let { data, code } = await newRiskProjectDetail(this.$route.query.id);
        this.detailData = data;
      } catch(err) {
      }
      this.$nextTick(() => {
        this.$refs["tabsModule" + this.tabsIndex][0].handleGetList();
      });
    },
    handleChangeDetails(e) {
      console.log(
        e.projectTargetDetailResp && e.projectTargetDetailResp.length
      );
      e.projectTargetDetailResp && e.projectTargetDetailResp.length
        ? (this.targetInfo =
            JSON.parse(JSON.stringify(e.projectTargetDetailResp)) || [])
        : "";
      e.fileList ? "" : (e.fileList = []);
      this.detailData = e;
    },
    handleTabClick(e) {
      if (e.index == "6") {
      } else {
        this.$nextTick(() => {
          this.$refs["tabsModule" + this.tabsIndex][0].handleGetList();
        });
      }
    },
    async getprojectFile(e) {
      this.fileloading = true;
      let { data, code } = await projectFilePage({
        index: 1,
        size: 999,
        projectId: this.detailData.id,
      });
      this.fileloading = false;
      if (code == 200 && data && data.records && data.records.length) {
        this.detailData.fileList = data.records.map((val) => {
          return {
            fileName: val.attName,
            fileType: val.attType,
            attSize: 999,
            oldFile: true,
            id: val.id,
            url: val.visitUrl,
          };
        });
      } else {
        this.detailData.fileList = [];
      }
    },
    handleReview(item) {
      this.trid = item.id;
    },
    async handleChangeDispaly(e, ind, item) {
      let keyType = {
        customerInfoList: 3,
        '4': 2,
        '5': 4,
        '6': 1,
      }
      const data = await swProjectDisplay({
        projectId: item.projectId,
        dataId: item.id,
        display: e,
        type: keyType[this.showContentData.statisticsData[ind].key]
      });
      if (data.code == 200) {
        this.$message.success('处理成功');
      }else {
        this.$message.error('处理失败');
      }
      // console.log(data);
      this.$refs["tabsModule" + this.tabsIndex][0].handleGetList();
    }
  },
};
</script>

<style lang="scss" scoped>
.detailData-text {
  font-size: 14px;
  color: #333;
  margin-top: 20px;
}
.project-details-tabs {
  ::v-deep .el-tabs--border-card {
    border: none;
    box-shadow: none;
    .el-tabs__content {
      border: none;
    }
    .el-tabs__header {
      width: initial !important;
      border: none;
      background-color: #eceeef !important;
      .el-tabs__item {
        border: none;
        font-size: 14px;
        color: #333333;
      }
      .is-active {
        font-size: 14px;
        font-weight: bold;
        color: #4278c9;
        border: none;
        border-bottom: none;
        border-radius: 4px 4px 0px 0px;
        background: #fff;
      }
    }
  }
}
</style>
<style lang="scss">
.step-one {
  .el-table__row {
    .cell {
      color: #333;
      font-size: 14px;
    }
  }
  .el-table__header-wrapper {
    background-color: #f0f4fb;
    // overflow: hidden;
  }
  .has-gutter,
  .el-table__fixed-header-wrapper {
    font-size: 14px;
    font-weight: bold;
    color: #333333;
    background: #f0f4fb;
    tr {
      background-color: #f0f4fb;
      border-radius: 8px;
      overflow: hidden;
    }
    th {
      background-color: transparent;
      font-size: 14px;
      font-weight: bold;
      color: #333333;
    }
  }
  .el-table__body {
    tr {
      position: relative;
    }
    // .table-del {
    //   position: absolute;
    //   top: 50%;
    //   right: -50px;
    //   transform: translateY(-50%);
    //   font-size: 30px;
    //   color: #c94242;
    //   cursor: pointer;
    // }
    .el-form-item {
      margin: 0;
    }
  }
  .plan-table {
    .has-gutter,
    .el-table__fixed-header-wrapper {
      tr {
        overflow: hidden;
      }
    }
    .el-table__body-wrapper {
      overflow: visible;
    }
  }
  .statistical-box {
    // width: 820px;
    padding: 24px 6px;
    &-title {
      position: relative;
      padding-left: 13px;
      &-text {
        display: flex;
        align-items: center;
        font-size: 16px;
        font-weight: bold;
        color: #333333;
      }
      &-time {
        color: #cccccc;
        font-size: 12px;
      }
    }
    &-title::before {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      width: 5px;
      background: #4278c9;
      border-radius: 3px;
    }
    &-content {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      margin-top: 24px;
      > div {
        width: 46%;
        display: flex;
        justify-content: space-between;
        font-size: 14px;
        > div {
          width: 112px;
          color: #999;
          margin-bottom: 23px;
          margin-right: 16px;
          text-align: right;
        }
        span {
          flex: 1;
          color: #333333;
        }
      }
    }
    &-decoration {
      &-top {
        display: flex;
        justify-content: space-between;
        &-tab {
          display: flex;
          div {
            width: 88px;
            height: 32px;
            line-height: 32px;
            text-align: center;
            font-size: 14px;
            color: #333333 !important;
            cursor: pointer;
            border-radius: 8px 8px 0px 0px;
            border: 1px solid #cccccc !important;
            border-bottom: none;
          }
          &-current {
            color: #333 !important;
            border: 1px solid #dee3e6 !important;
            background: #dee3e6 !important;
          }
        }
        &-download {
          display: flex;
          align-items: center;
          cursor: pointer;
          color: #0080ff;
          img {
            width: 14px;
            height: 14px;
          }
          span {
            margin-left: 2px;
            text-decoration: underline;
          }
        }
      }
      &-down {
        // display: flex;
        // flex-wrap: wrap;
        // justify-content: space-around;
        padding: 16px 0;
        height: 290px;
        overflow: auto;
        border-radius: 0px 8px 8px 8px;
        border: 1px solid #dee3e6 !important;
        &-content {
          width: 128px;
          height: 80px;
          margin-bottom: 8px;
          margin-left: 15px;
          border-radius: 4px;
        }
        &-box {
          display: flex;
          flex-wrap: wrap;
          width: 100%;
          &-time {
            width: 153px;
            height: 28px;
            line-height: 28px;
            font-size: 14px;
            color: #ffffff;
            padding-left: 8px;
            margin: 8px;
            margin-left: 16px;
            background: #4278c9;
          }
          &-title {
            width: 100%;
            display: flex;
            justify-content: space-between;
            font-size: 14px;
            font-weight: bold;
            color: #333333;
            padding: 0 16px;
            margin-bottom: 8px;
          }
        }
      }
    }
    .el-table {
      margin-bottom: 22px;
      .has-gutter {
        tr {
          .el-table__cell {
            background-color: #f2f7fd;
            font-size: 14px;
            color: #666666;
            font-weight: 400;
          }
          .is-leaf {
            border-bottom: 1px solid #dee3e6;
          }
        }
      }
      tr {
        .el-table__cell {
          font-size: 14px;
          color: #333;
          font-weight: 400;
        }
        .is-leaf {
          border-bottom: 1px solid #dee3e6;
        }
      }
    }
    .amap-info-content {
      padding: 16px 24px;
      background-color: rgba(255, 255, 255, 0.9);
      box-shadow: 0px 3px 6px 1px rgba(51, 51, 51, 0.1);
      border-radius: 10px;
    }
    .amap-info-sharp {
      display: none;
    }
  }
}
</style>
