<template>
  <div class="shell-box">
    <el-breadcrumb separator="/"
      class="breadcrumb">
      <el-breadcrumb-item :to="{ path: '/myHome' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: `/routerProjectManagement/projectManagementList` }">项目管理</el-breadcrumb-item>
      <el-breadcrumb-item>项目详情</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="form">
      <ProjectDetails :detailData="enquiryDetail" />
    </div>
    <div class="operating-box">
      <el-button size="mini"
        @click="handleBack">返回</el-button>
    </div>
  </div>
</template>

<script>
import ProjectDetails from "@/views/routerProjectManagement/components/ProjectChildrenList/components/details.vue";
import { insNewEnquiryDetail, getUpdateRecord, changeToInsurance, generateQuotationSheet, policyProcessGetNodeRecord, policyProcessRevoke, insConfirmationDetail } from "@/api/policy";
import { newRiskProjectDetail } from "@/api/basicData";
export default {
  components: { ProjectDetails },
  data() {
    return {
      enquiryDetail: {},
      confirData: {},
      updateRecordDetail: [],
      showUpload: false,
      dataEcho: {},
      isTurnPolicy: false,
      auditProcessList: []
    };
  },
  created() {
    // this.getDetail();
  },
  activated() {
    // this.getDetail();
  },
  methods: {
    getDetail() {
      // newRiskProjectDetail(this.$route.query.id).then(res => {
      //   this.enquiryDetail = res.data;
      //   this.$forceUpdate();
      // }).catch(err => {
      //   console.log(err);
      // });
    },
    handleBack() {
      this.$router.go(-1);
    },
    handleEnquiryEdit(e) {
      this.$router.push({
        path: `/routerPolicyManagement/${ this.$route.meta.policyRoute }/enquiryEdit/` + this.enquiryDetail.id + '/' + this.enquiryDetail.enquiryType
      });
    },
  }
}
</script>

<style lang="scss" scoped>
.shell-box {
  display: flex;
  flex-direction: column;
  height: 100%;
  .form {
    flex: 1;
    overflow: auto;
    padding-bottom: 20px;
  }
  .breadcrumb {
    padding: 10px 0;
    ::v-deep .el-breadcrumb__inner.is-link {
      color: rgba(0, 128, 255, 1);
    }
  }
  .operating-box {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: calc(100%);
    height: 50px;
    padding: 0 24px;
    background: #ffffff;
    box-shadow: 0px -3px 6px 1px rgba(0, 0, 0, 0.16);
    border-radius: 8px;
    z-index: 99;
  }
}
</style>